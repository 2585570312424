import React from 'react'
import styled from 'styled-components'
import { HeroImageTitle } from 'components/new/hero'
import { Helmet } from 'react-helmet'
import Layout from 'components/layout'
import { graphql, Link } from 'gatsby'
import YoutubePlaylist from 'components/youtube-playlist'
import Content from 'components/new/content'
import Spacer from 'components/new/spacer'
import Button from 'components/button/button'
import H2 from 'components/new/typography/h2'
import H3 from 'components/new/typography/h3'
import P from 'components/new/typography/p'
import { column } from 'styles/column'
import { clearfix } from 'styles/clearfix'
import {
  planterSetupUrl,
  operationsCenterUrl,
  starfireSoftwareUpdatesUrl,
} from 'data/clinics/planter-clinic-2021'
import { GatsbyImage } from 'gatsby-plugin-image'

const AMSBasics = props => {
  const {
    data: { heroImage, operationsCenterImage, receiverImage, rowUnitImage },
    location,
  } = props
  const params = new URLSearchParams(location.search)
  const videoId = params.get('videoId')
  return (
    <Layout>
      <Helmet>
        <title>AMS Basics | Planter Clinic 2021 | Hutson Inc</title>
      </Helmet>

      <Hero
        image={heroImage.childImageSharp.gatsbyImageData}
        title='AMS Basics'
        overlayOpacity={0.3}
      />

      <Content kind='full'>
        <H2>Common settings in your display</H2>
        <P>
          Get a refresher on common settings in your display. These tips will help get your planting
          season off to a smooth start and optimize your tractor and planter technology.
        </P>
      </Content>

      <YoutubePlaylist list='PLdopwvFEiG6VJVNvs-aQgTG89V1CkV_pP' videoId={videoId} />

      <Content kind='full'>
        <H2 style={{ textAlign: 'center' }}>More Planter Clinic Courses</H2>
        <Spacer size='m' />
        <Grid>
          <Column>
            <GatsbyImage image={rowUnitImage.childImageSharp.gatsbyImageData} alt='Planter Setup' />
            <ColumnContent>
              <H3>Planter Setup</H3>
              <Spacer size='s' />
              <Button
                as={ButtonLink}
                to={planterSetupUrl}
                ghost
                color='green'
                aria-label='View the Planter Setup Course'
              >
                View course
              </Button>
            </ColumnContent>
          </Column>
          <Column>
            <GatsbyImage
              image={operationsCenterImage.childImageSharp.gatsbyImageData}
              alt='Operations Center'
            />
            <ColumnContent>
              <H3>Operations Center</H3>
              <Spacer size='s' />
              <Button
                as={ButtonLink}
                to={operationsCenterUrl}
                ghost
                color='green'
                aria-label='View the Operations Center Course'
              >
                View course
              </Button>
            </ColumnContent>
          </Column>
          <Column>
            <GatsbyImage
              image={receiverImage.childImageSharp.gatsbyImageData}
              alt='StarFire Receiver'
            />
            <ColumnContent>
              <H3>StarFire Software Updates</H3>
              <Spacer size='s' />
              <Button
                as={ButtonLink}
                to={starfireSoftwareUpdatesUrl}
                ghost
                color='green'
                aria-label='View the Starfire Software Updates Course'
              >
                View course
              </Button>
            </ColumnContent>
          </Column>
        </Grid>
      </Content>
    </Layout>
  )
}

const Hero = styled(HeroImageTitle)`
  height: 300px;

  @media (min-width: 900px) {
    height: 450px;
  }
`

const Grid = styled.div`
  @media (min-width: 900px) {
    ${clearfix}
    display: flex;
    flex-direction: row;
  }
`

const Column = styled.div`
  background-color: #fff;
  text-align: center;

  h3 {
    text-align: center;
  }

  @media (max-width: 899px) {
    :not(:last-child) {
      margin-bottom: ${props => props.theme.size.l};
    }
  }

  @media (min-width: 900px) {
    ${column('1/3')}
    display: flex;
    flex-direction: column;

    .gatsby-image-wrapper {
      flex-grow: 2;
    }
  }
`

const ColumnContent = styled.div`
  padding: ${props => props.theme.size.m};
`

const ButtonLink = styled(Link)`
  display: inline-block;
  text-decoration: none;
`

export const pageQuery = graphql`
  {
    heroImage: file(relativePath: { eq: "clinics/planter-clinic-2021-hero.jpg" }) {
      ...FullWidthImage
    }
    rowUnitImage: file(relativePath: { eq: "clinics/row-unit.jpg" }) {
      ...SharpImage360
    }
    receiverImage: file(relativePath: { eq: "clinics/receiver.jpg" }) {
      ...SharpImage360
    }
    operationsCenterImage: file(relativePath: { eq: "clinics/operations-center.jpg" }) {
      ...SharpImage360
    }
  }
`

export default AMSBasics
